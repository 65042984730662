var header = document.querySelector('.header');
var overlay = document.querySelector('.w-nav-overlay');
var menu = document.querySelector('.w-nav-menu');
var navLinks = document.querySelectorAll('.w-nav-link');
var button = document.querySelector('.w-nav-button');

button.addEventListener('click', function () {
    if (menu.classList.contains('w--nav-menu-open')) {
        closeMenu();
    } else {
        openMenu();
    }
});

// When clicking outside of menu close menu
overlay.addEventListener('click', function () {
    closeMenu();
});

const closeMenu = () => {
    overlay.style.animation = 'height-to-0 0.5s ease-in-out';

    overlay.addEventListener('animationend', function animationEndHandler() {
        overlay.removeEventListener('animationend', animationEndHandler);
        
        // Reset styles and classes after animation completes
        menu.classList.remove('w--nav-menu-open');
        button.classList.remove('w--open');
        menu.removeAttribute('data-nav-menu-open');
        overlay.style.animation = ''; // Reset animation
        overlay.style.height = '0';
        
        navLinks.forEach(navLink => {
            navLink.classList.remove('w--nav-link-open');
        });
    });
};

const openMenu = () => {
    menu.classList.add('w--nav-menu-open');
    button.classList.add('w--open');
    overlay.appendChild(menu);
    menu.setAttribute('data-nav-menu-open', '');
    overlay.style.animation = 'height-to-vp 0.5s ease-in-out';
    overlay.style.display = 'block';
    overlay.style.height = '100vh';
    overlay.style.transition = 'height 0.5s ease-in-out';

    navLinks.forEach(navLink => {
        navLink.classList.add('w--nav-link-open');
    });
};
