"use strict";
// var $ = jQuery.noConflict();
import axios from 'axios';

// Class definition
var ThurContact = function () {
    // Elements
    var theForm;   
    var successMessage = document.querySelector('.success-message');
    var failMessage = document.querySelector('.w-form-fail');

    // Private Functions
    var _initContactForm = function () {

        theForm.addEventListener('submit', function(e) {
            e.preventDefault();
            var formData = new FormData(this);

            axios.post('/public/contact.php', formData)
            // axios.post('http://zhzlawoffice.test/public/contact.php', formData)
            .then(function (response) {
                if (response.data.info !== 'error') {
                    theForm.reset();
                    theForm.style.display = 'none';
                    successMessage.style.display = 'block';

                    setTimeout(function() {
                        successMessage.style.display = 'none';
                        theForm.style.display = 'flex';
                    }, 5500);

                } else {
                    failMessage.querySelector('div').textContent = response.data.msg;
                    failMessage.style.display = 'block';
                    setTimeout(function() {
                        failMessage.style.display = 'none';
                    }, 5500);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        });
    }

    return {
        init: function () {
            theForm = document.querySelector('#contact_form');
            
            if (!theForm) return;

            _initContactForm();
        }
    };
}();



// Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = ThurContact;
}

export default ThurContact;

//on document load, vanilla javascript
document.addEventListener("DOMContentLoaded", function() {
    ThurContact.init();
});